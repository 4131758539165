import { Tooltip } from "@material-ui/core";

import React from "react";

const DataBox = ({ metric, icon, value, backgroundColor, toolTipText }) => {
  return (
    <div className="data-box">
      <div className="icon-bubble" style={{ backgroundColor: backgroundColor }}>
        <i className={icon}></i>
      </div>
      <h2>{value}</h2>
      <div className="d-flex align-items-center">
        <h3>{metric}</h3>
        <Tooltip
          title={
            <h5 className="m-0 p-3" style={{ fontSize: 16 }}>
              {toolTipText}
            </h5>
          }
          style={{ marginLeft: 10 }}
        >
          <i className="far fa-info-circle"></i>
        </Tooltip>
      </div>
    </div>
  );
};

export default DataBox;
