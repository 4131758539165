import React from "react";
import ReactPlayer from "react-player";

const ResponsiveVideo = ({ url }) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        width="100%"
        height="100%"
        controls={true}
        url={url}
      />
    </div>
  );
};

export default ResponsiveVideo;
