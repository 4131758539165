import { Snackbar, TextField, Checkbox, Button } from "@material-ui/core";
import React from "react";
import venmoIcon from "../../images/venmo.svg";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";

const PaymentPreferences = ({ user, callback }) => {
  const [onlineAddress, setOnlineAddress] = React.useState("");
  const [agreed, setAgreed] = React.useState(false);
  const [saveAlert, setSaveAlert] = React.useState(false);

  React.useEffect(() => {
    getHubspotCompany();
    // eslint-disable-next-line
  }, []);

  const getHubspotCompany = () => {
    axios
      .post(`https://api.blizzard.lighting/get-hs-company`, {
        companyId: user.hasura.affiliateProgram.hubspotId,
      })
      .then((res) => {
        if (res.data.properties.paypal_venmo_account) {
          setOnlineAddress(res.data.properties.paypal_venmo_account.value);
        }
      })
      .catch((err) => console.log(err));
  };
  const saveOnlinePaymentAddress = () => {
    axios
      .post(`https://api.blizzard.lighting/update-company`, {
        companyId: user.hasura.affiliateProgram.hubspotId,
        properties: {
          paypal_venmo_account: onlineAddress,
        },
      })
      .then((res) => setSaveAlert(true))
      .catch((err) => console.log(err));
  };

  const validatePaymentAddress = () => {
    if (agreed && onlineAddress.length > 1) {
      return false;
    }
    return true;
  };

  const handleCallback = () => {
    if (callback) {
      callback();
    }
  };

  return (
    <>
      <Snackbar
        open={saveAlert}
        autoHideDuration={4000}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setSaveAlert(false)}
      >
        <Alert
          onClose={() => setSaveAlert(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Payment info saved!
        </Alert>
      </Snackbar>
      <h3 className="text-center pt-5">Select a Payout Preference</h3>
      <p className="text-center">Please contact us for additional options</p>
      <div className="row justify-content-around py-5">
        <div className="payment-box ">
          <div className="d-flex align-items-center justify-content-center">
            <i
              className="fab fa-paypal"
              style={{ marginRight: 20, fontSize: 40 }}
            ></i>
            <span style={{ marginRight: 20 }}>/</span>
            <img src={venmoIcon} style={{ width: 100 }} alt="Venmo" />
          </div>
          <h5 className="py-3">Online</h5>
          <TextField
            fullWidth
            label="Email/Username"
            variant="filled"
            value={onlineAddress}
            placeholder="Enter your PayPal/Venmo Email"
            onChange={(e) => setOnlineAddress(e.target.value)}
          />
          <div className="d-flex align-items-baseline">
            <Checkbox
              style={{ top: 6 }}
              color="primary"
              checked={agreed}
              onClick={() => setAgreed(!agreed)}
            />
            <p style={{ textAlign: "left" }}>
              I verify that this is the payment address my payment will be sent
              to.
            </p>
          </div>

          <Button
            onClick={() => saveOnlinePaymentAddress()}
            fullWidth
            style={{ marginTop: 30 }}
            variant="contained"
            color="primary"
            disabled={validatePaymentAddress()}
          >
            Save
          </Button>

          <Button
            onClick={() => handleCallback()}
            fullWidth
            style={{ marginTop: 10 }}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </div>
        {/* <div className="payment-box col-sm-5">
          <i style={{ fontSize: 40 }} className="fas fa-money-check"></i>
          <h5 className="py-3">Check</h5>
          <AddressForm user={user} />
        </div> */}
      </div>
    </>
  );
};

export default PaymentPreferences;
