export const colors = [
  "#ff73fa",
  "#f47b68",
  "#9b84ee",
  "#faa61a",
  "#44ddbf",
  "#f57731",
  "#f04747",
  "#7289da",
];

export const createMarkup = (markup) => {
  return { __html: markup };
};

export const validateUsername = (username) => {
  const regex = /^\w+$/;
  if (regex.test(username)) {
    return true;
  }
  return false;
};

export const generateKey = () => {
  return Math.floor(Math.random() * 1000000000);
};

export function avatarBackground(colorIndex) {
  switch (colorIndex) {
    case 1:
      return "#f47b68";
    case 2:
      return "#9b84ee";
    case 3:
      return "#faa61a";
    case 4:
      return "#44ddbf";
    case 5:
      return "#f57731";
    case 6:
      return "#f04747";
    case 7:
      return "#7289da";
    case 8:
    default:
      return "#ff73fa";
  }
}

export const validateEmail = (email) => {
  //eslint-disable-next-line
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  let testEmail = re.test(String(email).toLowerCase());
  return testEmail;
};

export const profileMenu = [
  {
    title: "All",
    filter: "",
  },
  {
    title: "Shows",
    filter: "text",
  },
  {
    title: "Videos",
    filter: "video",
  },
  {
    title: "Images",
    filter: "image",
  },
  // {
  //   title: "Fixture Profiles",
  // },
];
