import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import React, { useContext } from "react";
import { UserContext } from "../../context/User";
import _ from "lodash";
import { Check, ErrorOutline } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const AffliateSignUp = () => {
  const [referralCode, setRefferalCode] = React.useState("");
  const [codeIsUnique, setCodeIsUnique] = React.useState(true);
  const [contractId, setContractId] = React.useState(1);
  const [agreed, setAgreed] = React.useState(false);
  let history = useHistory();
  const { user } = useContext(UserContext);

  React.useEffect(() => {
    if (user && user.hasura.username) {
      setRefferalCode(user.hasura.username);
    }
  }, [user]);

  const validate = () => {
    if (contractId && referralCode && agreed) {
      return false;
    }
    return true;
  };

  const checkAffiliateCode = (code) => {
    axios
      .get(
        `https://atfullapi.blizzard.lighting/users/check-unique-referral-code?code=${code}`
      )
      .then((res) => {
        if (res.data.codeTaken) {
          setCodeIsUnique(false);
          return;
        }
        setCodeIsUnique(true);
        return;
      });
  };
  const debouncedApiCall = _.debounce((code) => checkAffiliateCode(code), 250);

  const submitAffiliateForm = () => {
    axios
      .get(
        `https://api.blizzard.lighting/hubspot/get-contact-by-email/${user.firebase.email}`
      )
      .then((res) => {
        const companyId = res.data.properties.associatedcompanyid.value;

        axios
          .post(
            "https://atfullapi.blizzard.lighting/users/affiliate",
            {
              referralCode: referralCode,
              contractId: contractId,
              hubspotId: companyId,
            },
            {
              headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
            }
          )
          .then((res) => {
            axios
              .post("https:/api.blizzard.lighting/update-company", {
                companyId: companyId,
                properties: {
                  company_type: "Pending Affiliate",
                },
              })
              .then((res) => {
                window.location.href = "/dashboard";
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="signup-wrapper">
      <div className="w-container">
        <h3 style={{ color: "black", textAlign: "center" }}>
          Become an Affiliate
        </h3>
        <TextField
          value={referralCode}
          label="Referral Code"
          fullWidth
          variant="filled"
          onChange={(e) => {
            setRefferalCode(e.target.value);
            debouncedApiCall(e.target.value);
          }}
          InputProps={{
            endAdornment: codeIsUnique ? (
              <Check style={{ color: "green" }} />
            ) : (
              <ErrorOutline style={{ color: "red" }} />
            ),
          }}
          helperText={
            codeIsUnique
              ? "This will be the unique code that your customers will use to redeem their offer."
              : "Your Referral Code is not unique"
          }
          error={!codeIsUnique}
        />

        <FormLabel style={{ marginTop: 30 }} component="legend">
          Payment Preference
        </FormLabel>
        <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
          <FormControlLabel
            value="Paid per referral"
            control={
              <Radio
                checked={contractId === 1}
                onChange={() => setContractId(1)}
                color="primary"
              />
            }
            label="Paid Per Referral"
          />
          <FormControlLabel
            value="One-time payment"
            control={
              <Radio
                checked={contractId === 2}
                onChange={() => setContractId(2)}
                color="primary"
              />
            }
            label="One-time Payment"
          />
        </RadioGroup>

        <a href="/" style={{ fontSize: ".8em", margin: "1em 0" }}>
          See sample contract ›
        </a>

        <div style={{ marginTop: 30 }} className="d-flex align-items-baseline">
          <Checkbox
            style={{ top: 6 }}
            color="primary"
            checked={agreed}
            onClick={() => setAgreed(!agreed)}
          />
          <p style={{ textAlign: "left" }}>
            I've read and agree with the
            <span style={{ fontWeight: "bold" }}>
              At Full Affiliate Terms of Service
            </span>
          </p>
        </div>

        <Button
          fullWidth
          style={{ marginTop: 30 }}
          variant="contained"
          color="primary"
          disabled={validate()}
          onClick={() => submitAffiliateForm()}
        >
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export default AffliateSignUp;
