import React, { useState } from "react";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import googleIcon from "../../images/google-icon.svg";
import appleIcon from "../../images/apple-icon.svg";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

const Signin = ({ title, refferal }) => {
  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");
  const [loading, setLoading] = useState(false);
  const [pw, setPw] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const urlParams = new URLSearchParams(window.location.search);

  //eslint-disable-next-line

  let validate = email && pw;

  const handleError = (error) => {
    setLoading(false);
    setError(error);
  };

  const handleSuccess = () => {
    const redirect = urlParams.get("redirect");

    setLoading(false);
    if (redirect) {
      window.location.href = `/${redirect}`;
    } else if (window.location.pathname.includes("product-registration")) {
      window.location.href = "/product-registration";
    } else {
      window.location.href = "/";
    }
  };

  const handleSignIn = () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, pw)
      .then((userCredential) => {
        handleSuccess();
      })
      .catch((error) => {
        handleError(error.message);
      });
  };

  //Google oAuth
  const openAuthProvider = (provider) => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user);
        handleSuccess();
      })
      .catch((error) => {
        const errorMessage = error.message;
        handleError(errorMessage);
      });
  };

  //Apple oAuth
  const openAuthApple = () => {
    const auth = getAuth();
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        handleSuccess();
      })
      .catch((error) => {
        const errorMessage = error.message;
        handleError(errorMessage);
      });
  };

  return (
    <div className="signup-wrapper">
      <div className="w-container">
        <h3 style={{ color: "black", textAlign: "center" }}>{title}</h3>
        <p className="text-center mb-4" style={{fontSize: 15}}>
          Sign in with your blizzardpro.com / At Full™ app credentials or select an alternate sign
          in option.
        </p>
        <div
          className="btn subtle"
          onClick={() => {
            openAuthProvider(googleProvider);
          }}
        >
          Continue with
          <img
            alt="Google"
            style={{ paddingLeft: 10, width: 24, paddingBottom: 0 }}
            src={googleIcon}
          />
        </div>
        <div
          style={{ marginTop: 20, marginBottom: 50 }}
          className="btn subtle"
          onClick={() => {
            openAuthApple();
          }}
        >
          Continue with
          <img
            alt="Apple"
            style={{ paddingLeft: 10, width: 24, paddingBottom: 0 }}
            src={appleIcon}
          />
        </div>

        <TextField
          label="Email"
          style={{ width: "100%" }}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
        />

        <TextField
          label="Password"
          autoComplete="off"
          margin="normal"
          style={{ width: "100%" }}
          variant="outlined"
          type="password"
          onChange={(e) => setPw(e.target.value)}
          inputProps={{
            name: "password",
          }}
        />

        <Button
          style={{ width: "100%" }}
          type="submit"
          onClick={() => handleSignIn()}
          variant="contained"
          color="primary"
          disabled={!validate && !loading}
        >
          Sign In
          {loading && <CircularProgress />}
        </Button>

        <a
          href={`https://login.blizzardlighting.com/?redirect=https://www.register.blizzardpro.com`}
          className="btn subtle create-account"
        >
          Create an Account
        </a>
        {error && (
          <p
            style={{ textTransform: "capitalize", marginTop: 30 }}
            className="highlight error"
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default withRouter(Signin);
