import { Button, CircularProgress, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useContext } from "react";
import { PostsContext } from "../../context/Posts";
import { UserContext } from "../../context/User";

const Commenter = ({ post, title, buttonText, customClass, hasAutoFocus }) => {
  const { user } = useContext(UserContext);
  const { updatePost, parentId, setParentId } = useContext(PostsContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userComment, setUserComment] = React.useState();

  const createComment = () => {
    setIsLoading(true);
    const params = {
      text: userComment,
      postId: post.id,
      parentCommentId: parentId ? parentId : null,
    };
    try {
      axios
        .post(" https://atfullapi.blizzard.lighting/comments", params, {
          headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
        })
        .then((res) => {
          setIsLoading(false);
          setParentId(null);
          setUserComment("");
          updatePost(post.id, res.data, parentId ? parentId : null);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`commenter ${customClass}`}>
      <h5>{title}</h5>
      <TextField
        autoFocus={hasAutoFocus}
        value={userComment}
        onChange={(e) => setUserComment(e.target.value)}
        multiline
        rows={4}
        variant="filled"
        style={{ width: "100%" }}
      />
      <Button
        disabled={userComment && userComment.length < 1}
        onClick={() => createComment()}
        style={{ marginTop: 20 }}
        className="btn gradient"
      >
        {isLoading ? <CircularProgress color="inherit" /> : buttonText}
      </Button>
    </div>
  );
};

export default Commenter;
