import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { avatarBackground, profileMenu } from "../../utils";
import proIcon from "../../images/pro-icon.png";
import PostList from "../PostList";
import { useContext } from "react";
import { PostsContext } from "../../context/Posts";
import _ from "lodash";
import { UserContext } from "../../context/User";
import FollowUserButton from "./FollowUserButton";
const Profile = () => {
  const [profile, setProfile] = React.useState({});
  const username = window.location.pathname.replace("/user/", "");

  const { fetchPosts, posts } = useContext(PostsContext);
  const { user } = useContext(UserContext);
  const [profilePhotos, setProfilePhotos] = React.useState([]);
  const [currentNavItem, setCurrentNavItem] = React.useState(0);
  const [fixtureProfiles, setFixtureProfiles] = React.useState();

  let headers;

  const fetchProfileById = async () => {
    if (user && user.firebase) {
      const userProfile = await axios.get(
        `https://atfullapi.blizzard.lighting/users/username/${username.toLowerCase()}`,
        headers
      );
      setProfile(userProfile.data);
      console.log(profile);
    }
  };

  React.useEffect(() => {
    if (user && user.firebase) {
      // eslint-disable-next-line
      headers = {
        headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
      };
    }

    fetchProfileById();
    try {
      fetchPosts(0, 10, "", false, username);
    } catch (err) {
      console.log(err);
    }

    const sortPosts = _.orderBy(posts, ["votes"], ["desc"]);
    const filterMedia = _.filter(sortPosts, (x) => {
      return x.imageUrl !== null;
    });

    if (filterMedia.length > 0) {
      setProfilePhotos([filterMedia[0].imageUrl, filterMedia[1].imageUrl]);
    }
  }, [user]);

  const renderUserBadge = () => {
    if (profile.userType === "pro") {
      return (
        <img
          style={{ width: 60, marginLeft: 10 }}
          src={proIcon}
          alt="Pro User"
        />
      );
    }
  };

  const filterPosts = (filter) => {
    fetchPosts(0, 10, "", false, username, filter);
  };

  const fetchFixtureProfiles = async () => {
    const profiles = axios.get();
  };

  const renderProfilePost = () => {
    if (profilePhotos.length >= 2) {
      return (
        <div className="featured-post-images">
          <img
            alt="Featured Post"
            className="featured-post w-100"
            src={profilePhotos[0]}
          />
          <img
            alt="Featured Post 2"
            className="featured-post w-100 top-post"
            src={profilePhotos[1]}
          />
        </div>
      );
    }
  };
  if (profile && profile.id) {
    return (
      <div className="mw-1920 ">
        <div className="profile-info row align-items-center">
          <div className="col-md-6 p-5">
            <div
              className="post-user-avatar bg-image large-avatar"
              style={{
                backgroundImage: `url(${profile.avatarUrl})`,
                backgroundColor: avatarBackground(
                  profile.avatarBackgroundColorIndex
                ),
              }}
            ></div>
            <div className="d-flex align-items-center">
              <h1>{profile.username}</h1>
              {renderUserBadge()}
              {user && user.hasura && (
                <FollowUserButton
                  userId={username}
                  isFollowing={profile.following}
                />
              )}
            </div>

            <div className="profile-stats d-flex">
              <span>
                <i className="fas fa-heart"></i> {profile.score}
              </span>

              <span>
                <i className="fas fa-comment-lines"></i> {profile.postCount}
              </span>

              {profile.location && profile.location.length > 0 && (
                <span>
                  <i className="fas fa-map-marker-alt"></i> {profile.location}
                </span>
              )}
            </div>

            {profile.bio && profile.bio.length > 0 && (
              <p className="pt-5" style={{ color: "gainsboro" }}>
                {profile.bio}
              </p>
            )}
          </div>
          <div className="col-md-6">{renderProfilePost()}</div>
        </div>

        <div className="d-flex profile-nav">
          {_.map(profileMenu, (nav, index) => {
            return (
              <div
                className={`nav-item ${
                  index === currentNavItem ? " active" : ""
                }`}
                onClick={() => {
                  setCurrentNavItem(index);

                  if (nav.filter) {
                    filterPosts(nav.filter);
                  } else {
                    console.log("here");
                  }
                }}
              >
                {nav.title}
              </div>
            );
          })}
        </div>

        <PostList />
      </div>
    );
  } else {
    return <CircularProgress className="m-auto mt-5 d-flex " />;
  }
};

export default Profile;
