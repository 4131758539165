import React from "react";
import { useContext } from "react";
import { PostsContext } from "../context/Posts";
import _ from "lodash";
import Post from "./Post";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { CircularProgress } from "@material-ui/core";

const PostList = () => {
  const [page, setPage] = React.useState(1);
  const { loadMorePosts, endOfPosts, isLoading, resultMessage } =
    useContext(PostsContext);
  const populatePosts = () => {
    if (!endOfPosts && !isLoading) {
      loadMorePosts(page + 1);
      setPage(page + 1);
    }
  };

  const renderPosts = () => {
    return _.map(posts, (post) => {
      return <Post post={post} />;
    });
  };
  const { posts } = useContext(PostsContext);
  return (
    <BottomScrollListener onBottom={() => populatePosts()}>
      <div className="post-list">{renderPosts()}</div>
      {isLoading && (
        <div className="d-flex justify-content-center">
          <CircularProgress color="primary" />
        </div>
      )}
      {resultMessage && <h3 className="text-center">{resultMessage}</h3>}
    </BottomScrollListener>
  );
};

export default PostList;
