import React, { useContext } from "react";
import PostUser from "./PostUser";
import _ from "lodash";
import { PostsContext } from "../context/Posts";
import Commenter from "./Posts/Commenter";
import { UserContext } from "../context/User";
import axios from "axios";

const Comment = ({ comment, post, parentId }) => {
  const { setParentId } = useContext(PostsContext);
  const { user } = useContext(UserContext);

  const deleteComment = async () => {
    await axios
      .delete(
        `https://atfullapi.blizzard.lighting/comments/${comment.id}`,

        {
          headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const renderDeleteIcon = () => {
    if (user && comment.createdBy.id === user.hasura.id) {
      return (
        <span onClick={() => deleteComment()} style={{ fontSize: ".75em" }}>
          <i className="far fa-trash"></i>
          Delete
        </span>
      );
    }
  };
  const renderReplies = () => {
    return _.map(comment.replies, (reply) => {
      return (
        <p className="comment-reply">
          <PostUser
            id={post.createdBy.id}
            avatarBackgroundColorIndex={
              reply.createdBy.avatarBackgroundColorIndex
            }
            className="reply"
            image={reply.createdBy.avatarUrl}
            username={reply.createdBy.username}
          />
          <div className="comment-text">
            <p>{reply.text}</p>
          </div>
          {renderDeleteIcon()}
        </p>
      );
    });
  };

  return (
    <div className="comment">
      <PostUser
        id={post.createdBy.id}
        avatarBackgroundColorIndex={
          comment.createdBy.avatarBackgroundColorIndex
        }
        image={comment.createdBy.avatarUrl}
        username={comment.createdBy.username}
        userType={comment.createdBy.userType}
      />

      <div className="comment-text">
        <p>{comment.deleted ? "[Deleted]" : comment.text}</p>
        {!comment.deleted && user && user.hasura && (
          <>
            <span
              onClick={() => {
                setParentId(comment.id);
              }}
              style={{ fontSize: ".75em" }}
            >
              <i className="fas fa-reply"></i> Reply
            </span>
            {renderDeleteIcon()}
          </>
        )}
      </div>

      {renderReplies()}

      {comment.id === parentId && (
        <Commenter
          hasAutoFocus
          customclassName="reply-commenter"
          title={`Replying to ${comment.createdBy.username}`}
          buttonText="Post"
          post={post}
        />
      )}
    </div>
  );
};

export default Comment;
