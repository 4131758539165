import React, { useContext } from "react";
import { Button, Dialog, Snackbar } from "@material-ui/core";
import { PostsContext } from "../context/Posts";
import axios from "axios";
import { Alert } from "@material-ui/lab";

const PostMeta = ({ user, post, comments, votes, showPostActions }) => {
  const { deletePost, likePost } = useContext(PostsContext);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [flagDialog, setFlagDialog] = React.useState(false);
  const [flaggedSuccess, setFlaggedSuccess] = React.useState(false);
  const [isLiked, setIsLiked] = React.useState();
  const [currentVotes, setCurrentVotes] = React.useState(0);

  React.useEffect(() => {
    setCurrentVotes(votes);
  }, [votes]);
  React.useEffect(() => {
    if (post.liked) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  }, [post]);

  const showDeleteIcon = () => {
    if (user && user.hasura.id === post.createdBy.id && showPostActions) {
      return (
        <div
          onClick={() => setDeleteDialog(true)}
          className="post-delete"
          style={{ marginRight: 30, marginLeft: 15 }}
        >
          <i className="far fa-trash"></i>
        </div>
      );
    }
  };
  const handleVote = () => {
    setIsLiked(!isLiked);
    likePost(post.id, !post.liked);
  };

  const flagPost = () => {
    axios
      .post(
        `https://atfullapi.blizzard.lighting/reports`,
        {
          reason: "Flagged on the web app",
          type: "post",
          reportedPostId: post.id,
        },
        {
          headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
        }
      )
      .then(() => {
        setFlaggedSuccess(true);
        setFlagDialog(false);
      })
      .catch((err) => console.log(err));
  };
  const renderPostActions = () => {
    if (showPostActions) {
      return (
        <div
          className="post-flag d-grid text-center"
          style={{ cursor: "pointer" }}
          onClick={() => setFlagDialog(true)}
        >
          <i className="far fa-pennant"></i>
          <span>Report</span>
        </div>
      );
    }
  };

  return (
    <>
      <Snackbar
        open={flaggedSuccess}
        autoHideDuration={4000}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={() => setFlaggedSuccess(false)}
      >
        <Alert
          onClose={() => setFlaggedSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Post flagged for review.
        </Alert>
      </Snackbar>
      <div className="post-meta">
        <Dialog open={deleteDialog} close={() => setDeleteDialog(false)}>
          <div className="container" style={{ padding: "2em" }}>
            <h5 style={{ marginBottom: 30, textAlign: "center" }}>
              Are you sure you want to delete this post?
            </h5>
            <div className="d-flex justify-content-between">
              <Button
                onClick={() => setDeleteDialog(false)}
                style={{ width: "48%" }}
                variant="contained"
                color="outline"
              >
                Close
              </Button>
              <Button
                onClick={() => deletePost(post.id)}
                style={{ width: "48%" }}
                variant="contained"
                color="primary"
              >
                Delete
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog open={flagDialog} close={() => flagDialog(false)}>
          <div className="container" style={{ padding: "2em" }}>
            <h5 style={{ marginBottom: 30, textAlign: "center" }}>
              Are you sure you want to flag this post?
            </h5>
            <div className="d-flex justify-content-between">
              <Button
                onClick={() => setFlagDialog(false)}
                style={{ width: "48%" }}
                variant="contained"
                color="outline"
              >
                Close
              </Button>
              <Button
                onClick={() => flagPost(post.id)}
                style={{ width: "48%" }}
                variant="contained"
                color="primary"
              >
                Flag
              </Button>
            </div>
          </div>
        </Dialog>

        {renderPostActions()}
        {showDeleteIcon()}

        <div
          className="post-votes-count d-grid text-center"
          style={{ cursor: "pointer" }}
          onClick={() => handleVote()}
        >
          <i className={`${isLiked ? "fas" : "far"} fa-heart`}></i>
          {isLiked ? currentVotes + 1 : votes}
        </div>
        <div className="post-comments-count d-grid text-center">
          <i className="far fa-comment-dots"></i> {comments}
        </div>
      </div>
    </>
  );
};

export default PostMeta;
