import React, { useState } from "react";

const WarrantyHeader = ({ view, setView }) => {
  return (
    <div className="warranty-header">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
           <a style={{color: "white"}} href="https://www.blizzardpro.com"><i style={{marginRight: 10}} className="fa-regular fa-arrow-left-from-arc"></i> Back to Blizzardpro.com</a>
       <div style={{display: "flex", alignItems: "center"}}>
       <div
       style={{marginRight: 15}}
          onClick={() => setView("registration")}
          className={`warranty-header-item ${
            view === "registration" ? "active" : null
          }`}
        >
          Registration
        </div>
        <div
          onClick={() => setView("products")}
          className={`warranty-header-item ${
            view === "products" ? "active" : null
          }`}
        >
          Your Products
        </div>
       </div>
      </div>
    </div>
  );
};

export default WarrantyHeader;
