import React, { createContext, useContext } from "react";
import _ from "lodash";
import axios from "axios";
import { UserContext } from "./User";

export const PostsContext = createContext();

export const PostsProvider = ({ children }) => {
  const [posts, setPosts] = React.useState([]);
  const { user } = useContext(UserContext);
  const [parentId, setParentId] = React.useState();
  const [endOfPosts, setEndOfPosts] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [resultMessage, setResultMessage] = React.useState();

  let headers;

  if (user && user.firebase) {
    headers = {
      headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
    };
  }

  const deletePost = (postId) => {
    axios
      .delete(`https://atfullapi.blizzard.lighting/posts/${postId}`, {
        headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
      })
      .then(() => {
        window.location.href = "/";
      })
      .catch((err) => console.log(err));
  };

  const likePost = (postId, liked) => {
    let currentPosts = [...posts];

    console.log(postId, liked);
    axios
      .post(
        `https://atfullapi.blizzard.lighting/vote/${postId}`,
        {},
        {
          headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
        }
      )
      .then(() => {
        const updatedPostIndex = _.findIndex(currentPosts, { id: postId });
        const votes = currentPosts[updatedPostIndex].votes;
        currentPosts[updatedPostIndex].liked = liked;
        currentPosts[updatedPostIndex].votes = liked ? votes + 1 : votes - 1;

        setPosts(currentPosts);
      })
      .catch((err) => console.log(err));
  };

  const updatePost = (postId, newPost, parentId) => {
    let currentPosts = [...posts];
    const updatedPostIndex = _.findIndex(currentPosts, { id: postId });

    if (parentId) {
      const commentIndex = _.findIndex(
        currentPosts[updatedPostIndex].comments,
        { id: parentId }
      );
      currentPosts[updatedPostIndex].comments[commentIndex].replies.push(
        newPost
      );
    } else {
      currentPosts[updatedPostIndex].comments.push(newPost);
    }

    setPosts(currentPosts);
  };

  const fetchPosts = (page, limit, searchTerm, additive, username, filter) => {
    setIsLoading(true);
    setResultMessage("");

    if (!additive) {
      setPosts([]);
    }
    // eslint-disable-next-line
    const fetchPosts = axios
      .get(
        `https://atfullapi.blizzard.lighting/posts?page=${page}&limit=${limit}&search=${searchTerm}${
          username ? `&username=${username}` : ""
        }${filter ? "&types=" + filter : ""}`,
        headers
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.length === 0 && !additive) {
          setPosts([]);
          setResultMessage("No posts found");
        }
        if (res.data.length === 0) {
          setEndOfPosts(true);
        } else if (additive) {
          setPosts([...posts, ...res.data]);
        } else {
          setPosts(res.data);
        }
      });
  };

  const loadMorePosts = (page) => {
    try {
      fetchPosts(page, 10, "", true);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (user && user.firebase.accessToken) {
      fetchPosts(1, 10, "");
    } else {
      fetchPosts(1, 10, "");
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <PostsContext.Provider
      value={{
        posts,
        resultMessage,
        endOfPosts,
        isLoading,
        likePost,
        loadMorePosts,
        deletePost,
        updatePost,
        setParentId,
        parentId,
        fetchPosts,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};
