import React, { useState } from "react";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Check, ErrorOutline } from "@material-ui/icons";
import ReactGA from "react-ga4";

const SignUp = () => {
  // const googleProvider = new GoogleAuthProvider();
  // const appleProvider = new OAuthProvider("apple.com");
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = React.useState("");
  const [pw, setPw] = useState("");
  const [pw2, setPw2] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contractId, setContractId] = React.useState();
  const [company, setCompany] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [usernameUnique, setUsernameUnique] = React.useState(true);
  const [error, setError] = useState("");
  const [isAffiliate, setIsAffiliate] = React.useState(false);
  const [isReferral, setIsReferral] = React.useState(false);
  //eslint-disable-next-line
  const urlParams = new URLSearchParams(window.location.search);

  let pwValidate = pw === pw2 && pw.length > 7;
  let nameValidate = firstName.length > 0 && lastName.length > 0;
  // let history = useHistory();
  const auth = getAuth();

  const validate = () => {
    if (isAffiliate) {
      return pwValidate && nameValidate && company.length > 1 && usernameUnique;
    } else {
      return pwValidate && nameValidate && usernameUnique;
    }
  };

  React.useEffect(() => {
    const getAffiliateParam = urlParams.get("affiliate");
    const getReferralCode = urlParams.get("ref");
    const getContractId = urlParams.get("contractId");
    if (getAffiliateParam === "1") {
      setIsAffiliate(true);
      setContractId(parseFloat(getContractId));
    }
    if (getReferralCode) {
      setIsReferral(true);
      setReferralCode(getReferralCode);
    }
  }, [urlParams]);

  const handleGAEvent = (type) => {
    if (type === "referral") {
      ReactGA.event({
        category: "button",
        action: "sign_up_web_ref",
        label: "button_web", // optional
      });
    } else {
      ReactGA.event({
        category: "button",
        action: "sign_up_web",
        label: "button_web", // optional
      });
    }
  };

  const checkUsername = (name) => {
    axios
      .post(`https://atfullapi.blizzard.lighting/users/check-username`, {
        name: name,
      })
      .then((res) => {
        const { nameTaken } = res.data;
        if (nameTaken) {
          setUsernameUnique(false);
          return;
        }
        setUsernameUnique(true);
        return;
      })
      .catch((err) => console.log(err));
  };

  const debouncedApiCall = _.debounce((name) => checkUsername(name), 250);

  const handleError = (error) => {
    setLoading(false);
    setError(error);
  };

  const handleSuccess = () => {
    setLoading(false);

    const urlParams = new URLSearchParams(window.location.search);
    const redir = urlParams.get("redir");

    if (redir) {
      window.location.href = `/${redir}`;
    } else if (isAffiliate) {
      window.location.href = "/dashboard";
    } else {
      window.location.href = "/?newUser=1";
    }
  };

  // Verify Claims
  const verifyTokenClaims = () => {
    auth.currentUser
      .getIdTokenResult(true)
      .then((idTokenResult) => {
        if (!!idTokenResult.claims["https://hasura.io/jwt/claims"]) {
          handleSignup(idTokenResult.token);
        } else {
          setTimeout(() => {
            verifyTokenClaims();
          }, 1000);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  //At Full API POST
  const handleSignup = async (userToken) => {
    if (isReferral) {
      //Create referral
      axios
        .post(
          " https://atfullapi.blizzard.lighting/users/referral",
          {
            referralCode: referralCode,
          },
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        )
        .then((res) => {
          //createhubspot contact
          axios
            .post("https://api.blizzard.lighting/update-contact", {
              email: email,
              firstName: firstName,
              lastName: lastName,
              full_user: true,
            })
            .then(() => {
              //create username
              axios.post(
                "https://atfullapi.blizzard.lighting/users/update",
                {
                  username: username,
                },
                {
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                }
              );
            })
            .then(() => {
              handleGAEvent("referral");
              handleSuccess();
            })
            .catch((err) => setError(err));
        })
        .catch((err) => setError(err.error));
    } else if (isAffiliate) {
      let contactId, companyId;
      axios
        .post("https://api.blizzard.lighting/update-contact", {
          email: email,
          firstName: firstName,
          lastName: lastName,
          company: company,
          at_full_referral_code: username,
          full_user: true,
        })
        .then(() => {
          axios
            .get(
              `https://api.blizzard.lighting/hubspot/get-contact-by-email/${email}`
            )
            .then((res) => {
              const { data } = res;
              contactId = data.vid;

              axios
                .post(`https://api.blizzard.lighting/hubspot/create-company`, {
                  name: company,
                  companyType: "Pending Affiliate",
                })
                .then((res) => {
                  companyId = res.data.companyId;
                  axios
                    .post(
                      `https://api.blizzard.lighting/hubspot/contact-to-company`,
                      {
                        contactId: contactId,
                        companyId: companyId,
                      }
                    )
                    .then((res) => {
                      const params = {
                        referralCode: username,
                        contractId: contractId ? contractId : 1,
                        hubspotId: companyId,
                      };

                      try {
                        axios
                          .post(
                            "https://atfullapi.blizzard.lighting/users/affiliate",
                            params,
                            {
                              headers: { Authorization: `Bearer ${userToken}` },
                            }
                          )
                          .then((res) => {
                            axios
                              .post(
                                "https://atfullapi.blizzard.lighting/users/update",
                                {
                                  username: username,
                                  name: firstName + " " + lastName,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${userToken}`,
                                  },
                                }
                              )
                              .then(() => {
                                handleSuccess();
                              });
                          });
                      } catch (err) {
                        setTimeout(() => {
                          axios
                            .post(
                              "https://atfullapi.blizzard.lighting/users/affiliate",
                              params,
                              {
                                headers: {
                                  Authorization: `Bearer ${userToken}`,
                                },
                              }
                            )
                            .then((res) => {
                              axios
                                .post(
                                  "https://atfullapi.blizzard.lighting/users/update",
                                  {
                                    username: username,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${userToken}`,
                                    },
                                  }
                                )
                                .then(() => {
                                  handleSuccess();
                                });
                            });
                        }, 1000);
                        handleError(err);
                      }
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    } else {
      let contactId, companyId;
      axios
        .post("https://api.blizzard.lighting/update-contact", {
          email: email,
          firstName: firstName,
          lastName: lastName,
          company: company,
          full_user: true,
        })
        .then(() => {
          axios
            .get(
              `https://api.blizzard.lighting/hubspot/get-contact-by-email/${email}`
            )
            .then((res) => {
              const { data } = res;
              contactId = data.vid;

              axios
                .post(`https://api.blizzard.lighting/hubspot/create-company`, {
                  name: company,
                  companyType: "End User",
                })
                .then((res) => {
                  companyId = res.data.companyId;
                  axios
                    .post(
                      `https://api.blizzard.lighting/hubspot/contact-to-company`,
                      {
                        contactId: contactId,
                        companyId: companyId,
                      }
                    )
                    .then(() => {
                      handleGAEvent();
                      handleSuccess();
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => console.log(err));
    }
  };

  // Email+PW Signup
  const firebaseSignUp = () => {
    setLoading(true);
    createUserWithEmailAndPassword(auth, email, pw)
      .then((userCredential) => {
        verifyTokenClaims();
      })
      .catch((error) => {
        const errorMessage = error.message
          .replace("Firebase: Error (auth/", "")
          .replace(")", "")
          .replace(/-/g, " ");

        handleError(errorMessage);
      });
  };

  //Google oAuth
  // const openAuthProvider = (provider) => {
  //   const auth = getAuth();
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       verifyTokenClaims();
  //     })
  //     .catch((error) => {
  //       const errorMessage = error.message;
  //       handleError(errorMessage);
  //     });
  // };

  //Apple oAuth
  // const openAuthApple = () => {
  //   const auth = getAuth();
  //   signInWithPopup(auth, appleProvider)
  //     .then((result) => {
  //       verifyTokenClaims();
  //     })
  //     .catch((error) => {
  //       const errorMessage = error.message;
  //       handleError(errorMessage);
  //     });
  // };

  return (
    <div className="signup-wrapper">
      <div className="w-container">
        <h3 style={{ color: "black", textAlign: "center" }}>
          Create an {isAffiliate ? "Affiliate" : ""} Account
        </h3>

        <Link
          className="underline text-center"
          style={{
            marginBottom: 30,
            justifyContent: "center",
            display: "flex",
          }}
          to="/sign-in"
        >
          Already have an account?
        </Link>
        {/*
        {!isAffiliate && (
          <>
            <div
              className="btn subtle"
              onClick={() => {
                openAuthProvider(googleProvider);
              }}
            >
              Continue with
              <img style={{ paddingLeft: 10, width: 24 }} src={googleIcon} />
            </div>
            <div
              style={{ marginTop: 20, marginBottom: 50 }}
              className="btn subtle"
              onClick={() => {
                openAuthApple();
              }}
            >
              Continue with
              <img style={{ paddingLeft: 10, width: 24 }} src={appleIcon} />
            </div>

            <h4 className="text-center">...or sign up with email</h4>
          </>
        )} */}

        <TextField
          label="Email"
          style={{ width: "100%", marginTop: 20 }}
          onChange={(e) => setEmail(e.target.value)}
          variant="filled"
        />

        <TextField
          fullWidth
          label="Username"
          variant="filled"
          margin="normal"
          helperText={usernameUnique ? "" : "This username is already taken"}
          error={!usernameUnique}
          onChange={(e) => {
            debouncedApiCall(e.target.value);
            setUsername(e.target.value);
          }}
          value={username}
          InputProps={{
            endAdornment:
              usernameUnique && username.length > 0 ? (
                <Check style={{ color: "green" }} />
              ) : (
                <ErrorOutline style={{ color: "red" }} />
              ),
          }}
        />

        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <TextField
            label="First Name"
            autoComplete="off"
            margin="normal"
            style={{ width: "48%" }}
            variant="filled"
            onChange={(e) => setFirstName(e.target.value)}
            inputProps={{
              name: "firstname",
            }}
          />

          <TextField
            label="Last Name"
            autoComplete="off"
            margin="normal"
            style={{ width: "48%" }}
            variant="filled"
            onChange={(e) => setLastName(e.target.value)}
            inputProps={{
              name: "lastname",
            }}
          />
        </div>
        {isReferral && (
          <TextField
            label="ReferralCode"
            margin="normal"
            style={{ width: "100%" }}
            variant="filled"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
        )}
        <TextField
          label="Company"
          margin="normal"
          style={{ width: "100%" }}
          variant="filled"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />

        <TextField
          label="Password"
          autoComplete="off"
          margin="normal"
          style={{ width: "100%" }}
          variant="filled"
          type="password"
          onChange={(e) => setPw(e.target.value)}
          error={pw.length < 8 && pw.length > 1}
          inputProps={{
            name: "password",
          }}
          helperText={
            pw.length < 8 && pw.length > 1
              ? "Please enter a password that is greater than 8 characters."
              : ""
          }
        />

        <TextField
          label="Confirm Password"
          autoComplete="off"
          margin="normal"
          style={{ width: "100%" }}
          onChange={(e) => setPw2(e.target.value)}
          variant="filled"
          error={pw !== pw2 && pw.length > 3 && pw2.length > 3}
          helperText={
            pw !== pw2 && pw.length > 3 && pw2.length > 3
              ? "Your passwords do no match."
              : ""
          }
          type="password"
          inputProps={{
            name: "confirm-password",
          }}
        />

        <Button
          style={{ width: "100%" }}
          type="submit"
          onClick={() => firebaseSignUp(pw)}
          variant="contained"
          color="primary"
          disabled={!validate() && !loading}
        >
          Sign Up
          {loading && <CircularProgress />}
        </Button>
        {error && (
          <p
            style={{ textTransform: "capitalize", marginTop: 30 }}
            className="highlight error"
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default withRouter(SignUp);
