import { Button } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import ImageIcon from "@material-ui/icons/Image";
import _ from "lodash";

const WarrantyUploader = ({ maxFileSize, setReceipt, receipt }) => {
  const handleChange = (files) => {
    if (receipt.length > 0 && files.length === 0) {
      return;
    }
    setReceipt(files);
  };

  const renderReceipts = () => {
    return _.map(receipt, (r) => {
      const imageUrl = URL.createObjectURL(r);
      return (
        <div className="receipt-item" key={imageUrl}>
          <div onClick={() => setReceipt([])} className="remove-can">
            <i className="fas fa-trash"></i>{" "}
          </div>
          <img src={imageUrl} alt="Image" />
        </div>
      );
    });
  };
  return (
    <React.Fragment>
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <h3 className="reveal-text">Snap a pic of your receipt</h3>
      </div>
      <div className="drag">
        <DropzoneArea
          filesLimit={1}
          dropzoneclassName="file-uploader"
          maxFileSize={maxFileSize}
          acceptedFiles={["application/*", "", "image/*", "video/*"]}
          onChange={handleChange}
          showPreviewsInDropzone={false}
          dropzoneText="Drag receipt file here or click to upload from your computer"
          Icon={ImageIcon}
          onDropRejected={(file) => console.log(file)}
        />
        <div className="warranty-receipts">{renderReceipts()}</div>
      </div>
    </React.Fragment>
  );
};

export default WarrantyUploader;
