import {
  TextField,
  InputAdornment,
  Button,

  CircularProgress,
  Checkbox,

} from "@material-ui/core";
import React, { useContext, useState } from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import WarrantyUploader from "./WarrantyUploader";
import WarrantyProductAdd from "./WarrantyProductAdd";
import WarrantyHeader from "./WarrantyHeader";
import { validateEmail } from "../../utils";
import SuccessAni from "./SuccessAni";
import moment from "moment";
import blizzardLogo from "../../images/blizzard-light.svg";
import { useWindowWidth } from "../../hooks/useWindowSize";
import axios from "axios";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import _ from "lodash";
import { UserContext } from "../../context/User";
import WarrantyLibrary from "./WarrantyLibrary";
import { withRouter } from "react-router-dom";
import Signin from "../Auth/Signin";

const WarrantyCenter = ({ history }) => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState("");
  const [searchingSo, setSearchingSo] = useState(false);
  const [dateOfPurchase, setDateOfPurchase] = useState(new Date());
  const [receipt, setReceipt] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const [view, setView] = useState("registration");
  const [agreed, setAgreed] = useState(false);
  const { user } = useContext(UserContext);
  const [soNum, setSoNum] = useState("");
  const [purchaseType, setPurchaseType] = useState("thirdparty");
  const [soError, setSoError] = useState();
  let headers;
  if (user && user.firebase) {
    headers = {
      headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
    };
  }

  const handleDateChange = (date) => {
    setDateOfPurchase(moment(date).format("YYYY-MM-DD"));
  };

  React.useEffect(() => {
    if (user && user.firebase.email) {
      setEmail(user.firebase.email);
    }

    axios
      .get("https://atfullapi.blizzard.lighting/products")
      .then((res) => setProducts(res.data))
      .catch((err) => console.log(err));
  }, [user]);

  const findProduct = (id) => {
    const foundProduct = _.find(products, { id: id });
    console.log(findProduct);
  };

  const uploadReceiptToFirebase = (file) => {
    const date = Date.now();
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `/receipts/${user.firebase.uid}/${date}.${file.type.replace(
        "image/",
        ""
      )}`
    );

    uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        let payload = {
          purchaseDate: dateOfPurchase,
          receiptUrl: url,
          products: [...userProducts],
        };

        registerProduct(payload);
      });
    });
  };

  const registerProduct = (payload) => {
    axios
      .post(
        "https://atfullapi.blizzard.lighting/products/registration",
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.firebase.accessToken}`,
          },
        }
      )
      .then((hasuraRes) => {
        setIsLoading(false);
        setStep(4);
      })
      .catch((err) => {
        setIsLoading(true);
      });
  };
  const submitRegistration = async () => {
    setIsLoading(true);
    try {
      if (receipt.length > 0) {
        const r = receipt[0];
        uploadReceiptToFirebase(r);
      } else {
        let payload = {
          purchaseDate: dateOfPurchase,
          products: [...userProducts],
        };

        registerProduct(payload);
      }
    } catch (err) {
      setIsLoading(true);
    }
  };

  const clearForm = () => {
    setStep(1);
    setSoNum();
    setReceipt([]);
    setUserProducts([]);
  };

  const windowWidth = useWindowWidth();

  const renderAdornment = () => {
    if (userProducts && userProducts.length > 0 && !searchingSo) {
      return (
        <i className="fas fa-check-circle" style={{ color: "#1bc891" }}></i>
      );
    } else if (searchingSo) {
      return <CircularProgress />;
    }
    return;
  };
  const findSO = (e) => {
    axios
      .post(
        `https://api.blizzard.lighting/fishbowl/registration/get-so`,
        { soNum: e },
        headers
      )
      .then((result) => {
        setSoError();
        setSearchingSo(false);
        setUserProducts([...result.data]);
      })
      .catch((err) => {
        setSoError(
          "This order was not found. Make sure your order number is correct."
        );
        setSearchingSo(false);
      });
  };
  const debouncedApiCall = _.debounce((e) => {
    findSO(e);
  }, 1000);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="warranty-purchase-flex">
              <div
                style={{ margin: "0 auto", textAlign: "center", width: "100%" }}
              >
                <h3 className="reveal-text">Purchase Info</h3>
              </div>
              <TextField
                        style={{ width: "100%", marginBottom: 20 }}
                        label=""
                        margin="normal"
                        variant="outlined"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="Email Address"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <i className="fas fa-envelope"></i>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          inputVariant="outlined"
                          format="MM/DD/yyyy"
                          autoOk
                          value={dateOfPurchase}
                          style={{ width: "100%", marginTop: 18 }}
                          onChange={handleDateChange}
                          helperText="Enter the date of purchase on your receipt"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="fas fa-calendar-alt"></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>


            </div>
          </>
        );
      case 2:
        return (
          <>
            <WarrantyUploader
              receipt={receipt}
              maxFileSize={500000000}
              uploadType="media"
              metadata={true}
              setReceipt={setReceipt}
            />
          </>
        );
      case 3:
        return (
          <>
            <h3 className="reveal-text centered-text">Choose Products</h3>

            <WarrantyProductAdd
              purchaseType={purchaseType}
              dateOfPurchase={dateOfPurchase}
              products={products}
              userProducts={userProducts}
              setUserProducts={setUserProducts}
            />

            <div
              style={{ marginTop: 10 }}
              className="d-flex align-items-center"
            >
              <Checkbox
                color="secondary"
                onClick={() => setAgreed(!agreed)}
                checked={agreed}
              />
              <p style={{ textAlign: "left", margin: 0, fontSize: 15 }}>
                I acknowledge that the validity of my warranty is subject to
                verification by Blizzard Lighting, LLC and I have reviewed the
                full warranty requirements
                <a
                  style={{ color: "black", paddingLeft: 8 }}
                  target="_blank"
                  href="https://www.blizzardpro.com/warranty"
                >
                  here.
                </a>
              </p>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <SuccessAni
              marginTop={20}
              title="Submitted Successfully"
            ></SuccessAni>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => clearForm()}
                type="submit"
                style={{ marginRight: 10, width: 250 }}
                variant="contained"
                color="primary"
                className="btn"
              >
                Submit Another
              </Button>
              <Button
                onClick={() => setView("products")}
                type="submit"
                style={{ width: 250 }}
                variant="contained"
                color="primary"
                className="btn"
              >
                View Products
              </Button>
            </div>
          </>
        );
      default:
        return "Nothing";
    }
  };

  const renderSignUpButtons = () => {
    return (
      <div className="sign-in">
        <Signin
          refferal="product-registration"
          title="Sign in to activate your warranty"
        />
      </div>
    );
  };

  const getProgress = () => {
    switch (step) {
      case 1:
        return "10%";
      case 2:
        return "35%";
      case 3:
        return "65%";
      case 4:
        return "100%";

      default:
        return "100%";
    }
  };

  const handleStep = (dir) => {

    if (dir === "prev") {
      setStep(step - 1);
    } else {
      setStep(step + 1);
    }
  };

  const isDisabled = () => {
    const step1Complete =
      validateEmail(email) && dateOfPurchase && purchaseType;
    const step2Complete = receipt.length > 0 || purchaseType === "direct";
    const step3Complete = userProducts.length > 0 && agreed;

    if (step === 1 && step1Complete) {
      return false;
    } else if (step === 2 && step2Complete) {
      return false;
    } else if (step === 3 && step3Complete) {
      return false;
    } else return true;
  };

  return (
    <div className="warranty-center">
      {user && <WarrantyHeader view={view} setView={setView} />}
      {view === "registration" ? (
        <div>
          <div className="flex-container">
            <div style={{width: "100%"}}>
            <div className="warranty-text-headline">
              <div className="register-header-left">
                <img
                  width={250}
                  style={{ margin: "0 auto" }}
                  src={blizzardLogo}
                  alt="Blizzard Lighting"
                />
              </div>
              <div className="register-header-right">

                <div style={{width: "100%"}}>
                <div className="centered-text" id="warranty-text-header">
                  <h2>Register Your <br/> Blizzard Lighting™ Products</h2>

                  <p>
                    Product registration extends the warranty on certain
                    products.

                    View the
                    <a
                      target="_blank"
                      href="https://www.blizzardpro.com/warranty"
                    >
                      terms and conditions
                    </a>
                    to see if the products you're registering qualify.
                  </p>
                </div>
              </div>
                </div>

            </div>
            </div>


            {user && (
              <div className="progress-bar">
                <div className="progress-icons">
                  <div
                    className={`progress-icon ${step === 1 ? "active" : null}`}
                  >
                    <i className="fal fa-user-circle"></i>
                    <p>Purchase Information</p>
                  </div>
                  <div
                    className={`progress-icon ${step === 2 ? "active" : null}`}
                  >
                    <i className="fal fa-receipt"></i>
                    <p>Receipt Upload</p>
                  </div>
                  <div
                    className={`progress-icon ${step === 3 ? "active" : null}`}
                  >
                    <i className="fal fa-lightbulb"></i>

                    <p>Product Selection</p>
                  </div>
                  <div
                    className={`progress-icon ${step === 4 ? "active" : null}`}
                  >
                    <i className="fal fa-check-circle"></i>
                  </div>
                </div>
                <div className="progress-tube">
                  <div
                    style={{ height: "100%", width: getProgress() }}
                    className="tube-gel"
                  ></div>
                </div>
              </div>
            )}
            {!user && renderSignUpButtons()}
            {user && (
              <div className="form-container">
                <div className="info-form">{renderStep()}</div>
                <div className="button-group">
                  {step > 1 && step !== 4 && (
                    <Button
                      type="submit"
                      onClick={() => handleStep("prev")}
                      style={{ width: 150, marginRight: 20 }}
                      variant="contained"
                      color="primary"
                      className="btn"
                      id="dark-bg-button"
                    >
                      Previous
                    </Button>
                  )}

                  {step === 3 ? (
                    <Button
                      id="dark-bg-button"
                      type="submit"
                      onClick={() => submitRegistration()}
                      style={{ width: 150 }}
                      variant="contained"
                      color="primary"

                      className="btn"
                    >
                      {isLoading ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  ) : (
                    <Button
                      id="dark-bg-button"
                      type="submit"
                      onClick={() => handleStep()}
                      style={{ width: 150 }}
                      variant="contained"
                      color="primary"
                      disabled={isDisabled()}
                      className="btn"
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <WarrantyLibrary />
      )}
    </div>
  );
};
export default WarrantyCenter;