import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import axios from "axios";
import _, { uniq } from "lodash";
import React, { useContext, useState } from "react";
import { UserContext } from "../../context/User";
import moment from "moment";
import ModalBasic from "../ModalBasic";
const WarrantyLibrary = ({ props }) => {
  const { user } = useContext(UserContext);
  const [registrations, setRegistrations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [viewingReceipt, setViewingReceipt] = useState(false);
  const [registeredProducts, setRegisteredProducts] = useState([]);
  let headers;
  if (user && user.firebase) {
    headers = {
      headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
    };
  }

  const fetchRegistrations = () => {
    setIsLoading(true);
    axios
      .get(
        `https://atfullapi.blizzard.lighting/products/registrations`,

        headers
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          let prods = [];

          _.map(res.data, (reg, index) => {
            _.map(reg.products, (prod) => {
              console.log(prod);
              prods.push({
                ...prod.product,
                serialNumber: prod.serialNumber,
                purchaseDate: reg.purchaseDate,
                warrantyExpiration: prod.warrantyExpiration,
                receiptUrl: reg.receiptUrl,
              });
            });

            if (index === res.data.length - 1) {
              setRegisteredProducts(prods);
            }
          });

          setRegistrations(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const renderRegistrationInfo = (registration, productId) => {
    const prod = _.filter(registeredProducts, { id: productId });

    return _.map(prod, (reg) => {
      return (
        <div className="registration-flex">
          <div className="registration-meta">
            <div className="registration-serial">
              Serial #: {reg.serialNumber}
            </div>
            <div className="registration-date">
              {moment(reg.purchaseDate).format("MM/DD/YYYY")} -{" "}
              {moment(reg.warrantyExpiration).format("MM/DD/YYYY")}
            </div>
          </div>
          {reg.receiptUrl.length > 0 && (
            <div
              onClick={() => {
                setSelectedProduct(reg);
                setViewingReceipt(true);
              }}
              className="registration-receipt"
            >
              <span>View Receipt</span>
              <i className="fas fa-receipt"></i>
            </div>
          )}
        </div>
      );
    });
  };

  const renderRegistrations = (id) => {
    let findRegistrations = [];
    const filter = _.map(registrations, (reg) => {
      _.map(reg.products, (prod) => {
        if (prod.product.id === id) {
          findRegistrations.push(reg);
        }
      });
    });

    return (
      <div className="registration-product">
        {renderRegistrationInfo(findRegistrations, id)}
      </div>
    );
  };

  const renderRegisteredProducts = () => {
    if (registeredProducts.length > 0) {
      const uniqProducts = _.uniqBy(registeredProducts, "name");

      return _.map(uniqProducts, (regProduct) => {
        return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div className="registration-item">
                <div className="registration-item-container">
                  <div className="registration-item-image">
                    <div className="registration-bubble">
                      <img src={regProduct.imageUrl} alt={regProduct.name} />
                    </div>
                  </div>
                  <h2>{regProduct.name}</h2>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {renderRegistrations(regProduct.id)}
            </AccordionDetails>
          </Accordion>
        );
      });
    }
  };

  const determineLoadingState = () => {
    if (isLoading) {
      return (
        <div className="full-height-container">
          <CircularProgress />
        </div>
      );
    } else if (!isLoading && registeredProducts.length === 0) {
      return (
        <div className="full-height-container">
          <h3>You have no registered products</h3>
        </div>
      );
    } else {
      return null;
    }
  };
  React.useEffect(() => {
    if (user) {
      fetchRegistrations();
    }
  }, [user]);

  return (
    <div className="warranty-library">
      <ModalBasic open={viewingReceipt} close={() => setViewingReceipt(false)}>
        <div className="receipt-container">
          <img src={selectedProduct.receiptUrl} alt="receipt" />
        </div>
      </ModalBasic>
      {determineLoadingState()}
      {renderRegisteredProducts()}
    </div>
  );
};

export default WarrantyLibrary;
