import React from "react";
import PostList from "./PostList";

const SearchPage = () => {
  return (
    <div className="public-front-page mw-1920">
      <PostList />
    </div>
  );
};

export default SearchPage;
