import React, { useContext } from "react";
import PostMeta from "./PostMeta";
import PostModal from "./PostModal";
import PostUser from "./PostUser";
import ResponsiveVideo from "./ResponsiveVideo";
import CommentList from "./CommentList";
import { UserContext } from "../context/User";

const Post = ({ post }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { user } = useContext(UserContext);
  const [isMobile, setIsMobile] = React.useState(false);
  const votes = post.votes;

  React.useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobile(true);
    }
  }, []);

  const renderCardImagery = () => {
    if (post.postType === "video") {
      return (
        <div
          className="post-image bg-image"
          onClick={() => setModalIsOpen(true)}
          style={{
            backgroundImage: `url(${post.imageUrl})`,
          }}
        >
          <div className="post-placeholder"></div>

          <div className="video-container">
            <i className="fas fa-play play-button"></i>
          </div>
        </div>
      );
    } else if (post.postType === "text") {
      return (
        <div
          className="post-image bg-image placeholder-ani"
          style={{
            color: "red",
            backgroundImage: `url(https://source.unsplash.com/user/tyhahn13/likes?sig=${post.id})`,
          }}
          onClick={() => setModalIsOpen(true)}
        >
          <div className="post-placeholder">{post.caption}</div>
        </div>
      );
    } else {
      return (
        <div
          className="post-image bg-image"
          onClick={() => setModalIsOpen(true)}
          style={{ backgroundImage: `url(${post.imageUrl})` }}
        >
          <div className="post-placeholder"></div>
        </div>
      );
    }
  };

  const renderContent = () => {
    if (post.postType === "video") {
      return (
        <div>
          <ResponsiveVideo url={post.videoUrl} />
        </div>
      );
    } else if (post.imageUrl) {
      return (
        <div className="post-modal-content">
          <img
            className="w-100 border-radius"
            src={post.imageUrl}
            alt={post.caption}
          />
        </div>
      );
    }
  };

  const buildUrl = () => {
    return `https://atfull.page.link/?isi=1483078784&ibi=com.blizzardpro.atFullController&link=https%3A%2F%2Fatfull.page.link%2Fposts%2F${
      post.id
    }&si=${encodeURIComponent(post.imageUrl)}&sd=${
      post.caption
    }&ius=com.blizzardpro.atFullController&st=Check%20this%20out%21&apn=com.blizzardpro.atfull`;
  };

  const renderOpenInAtFullButton = () => {
    return (
      <a
        style={{ color: "white" }}
        href={isMobile ? buildUrl() : "https://atfull.app.link/c2Zav2uc7jb"}
        className="btn gradient"
      >
        {isMobile ? "Open in At Full ›" : "Get At Full to Download ›"}
      </a>
    );
  };

  const openInAtFullButton = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="post-modal-button">{renderOpenInAtFullButton()}</div>

        <PostMeta
          showPostActions
          user={user}
          post={post}
          votes={votes}
          comments={post.comments.length}
        />
      </div>
    );
  };

  return (
    <div className="post">
      <PostModal open={modalIsOpen} close={() => setModalIsOpen(false)}>
        <div className="post-modal">
          <div className="post-modal-content">
            <div className="post-modal-meta d-flex justify-content-between align-items-center">
              <PostUser
                id={post.createdBy.id}
                caption={post.caption}
                image={post.createdBy.avatarUrl}
                username={post.createdBy.username}
                userType={post.createdBy.userType}
                avatarBackgroundColorIndex={
                  post.createdBy.avatarBackgroundColorIndex
                }
              />
            </div>
            {renderContent()}
            {openInAtFullButton()}
            <CommentList post={post} comments={post.comments} />
          </div>
        </div>
      </PostModal>
      {renderCardImagery()}
      <div className="post-description">
        <PostUser
          id={post.createdBy.id}
          image={post.createdBy.avatarUrl}
          username={post.createdBy.username}
          userType={post.createdBy.userType}
          avatarBackgroundColorIndex={post.createdBy.avatarBackgroundColorIndex}
        />
        <PostMeta
          user={user}
          post={post}
          votes={votes}
          comments={post.comments.length}
        />
      </div>

      <div className="post-caption" onClick={() => setModalIsOpen(true)}>
        {post.postType !== "text" && post.caption}
      </div>
    </div>
  );
};

export default Post;
