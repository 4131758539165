import React, { useContext } from "react";
import { colors } from "../../utils";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { Button } from "@material-ui/core";
import { UserContext } from "../../context/User";

const AvatarSelect = ({ callback, buttonTitle }) => {
  const storage = getStorage();
  const [avatarUrls, setAvatarUrls] = React.useState([]);
  const [selectedColor, setSelectedColor] = React.useState(0);
  const [selectedAvatar, setSelectedAvatar] = React.useState(0);
  const { saveUserInfo } = useContext(UserContext);

  React.useEffect(() => {
    const listRef = ref(storage, "/default-avatars");
    let urls = [];
    let i = 0;
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            i++;
            if (url.includes("thumb")) urls.push(url);
            if (i === res.items.length) {
              setAvatarUrls(urls);
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storage]);

  const renderDefaultAvatars = () => {
    if (avatarUrls.length > 0) {
      return avatarUrls.map((url, i) => {
        return (
          <div onClick={() => setSelectedAvatar(i)} className="icon-box">
            <img src={url} alt="" />
          </div>
        );
      });
    }
  };

  const renderColors = () => {
    if (colors) {
      return colors.map((color, i) => {
        return (
          <div
            className={`color-box ${selectedColor === i ? "active" : ""}`}
            onClick={() => setSelectedColor(i)}
            style={{ backgroundColor: color }}
          ></div>
        );
      });
    }
  };

  return (
    <div className="avatar-select">
      <h4>Edit your avatar</h4>
      <div className="d-flex justify-content-center">
        <div className="col-sm-3">
          <div className="color-select">{renderColors()}</div>
        </div>
        <div className="col-sm-8">
          <div
            className="selected-avatar"
            style={{ backgroundColor: colors[selectedColor] }}
          >
            <img
              style={{ maxWidth: "100%" }}
              src={avatarUrls[selectedAvatar]}
              alt=""
            />
          </div>
        </div>
      </div>
      <h5>Select Icon</h5>
      <div className="icon-select">{renderDefaultAvatars()}</div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          saveUserInfo({
            avatarUrl: avatarUrls[selectedAvatar],
            avatarBackgroundColorIndex: selectedColor,
          });
          if (callback) {
            callback();
          }
        }}
        style={{ width: "100%", marginTop: 30 }}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};

export default AvatarSelect;
