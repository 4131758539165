import React from "react";
import { Link } from "react-router-dom";
import proIcon from "../images/pro-icon.png";
import { avatarBackground } from "../utils";

const PostUser = ({
  image,
  username,
  caption,
  avatarBackgroundColorIndex,
  userType,
}) => {
  const renderUserBadge = () => {
    if (userType === "pro") {
      return (
        <img
          style={{ width: 25, marginLeft: 10 }}
          src={proIcon}
          alt="Pro User"
        />
      );
    }
  };

  return (
    <div className="post-user">
      <Link to={`/user/${username}`}>
        <div
          className="post-user-avatar bg-image"
          style={{
            backgroundImage: `url(${image})`,
            backgroundColor: avatarBackground(avatarBackgroundColorIndex),
          }}
        ></div>
      </Link>

      <div className="d-flex flex-wrap">
        {caption && <div className="post-modal-caption">{caption}</div>}
        <div className="post-username d-flex align-items-center">
          {username} {renderUserBadge()}
        </div>
      </div>
    </div>
  );
};

export default PostUser;
