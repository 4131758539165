import {
  Dialog,
  TextField,
  IconButton,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

import React, { useContext } from "react";
import { UserContext } from "../../context/User";
import proIcon from "../../images/pro-icon.png";
import { avatarBackground } from "../../utils";
import AvatarSelect from "./AvatarSelect";
import { Close } from "@material-ui/icons";
import axios from "axios";

const Account = () => {
  const { user, saveUserInfo } = useContext(UserContext);
  const [dialog, setDialog] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [saveAlert, setSaveAlert] = React.useState(false);
  const [referralCode, setreferralCode] = React.useState("");
  const [referralMsg, setReferralMsg] = React.useState();

  React.useEffect(() => {
    if (user && user.hasura) {
      setUsername(user.hasura.username);
    }
  }, [user]);

  const redeemReferralCode = () => {
    if (referralCode.length > 0) {
      axios
        .post(
          "https://atfullapi.blizzard.lighting/users/referral",
          { referralCode: referralCode },
          {
            headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
          }
        )
        .then((res) => {
          console.log(res);
          setReferralMsg("Successfully redeemed referral code.");
        })
        .catch((err) => {
          setReferralMsg("That promotion was not found. Try again.");
        });
    }
  };

  if (user) {
    return (
      <div className="account-management mw-1240 mb-5">
        <Snackbar
          open={saveAlert}
          autoHideDuration={4000}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={() => setSaveAlert(false)}
        >
          <Alert
            onClose={() => setSaveAlert(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Profile saved!
          </Alert>
        </Snackbar>
        <Dialog open={dialog} onClose={() => setDialog(false)}>
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setDialog(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <AvatarSelect
            buttonTitle="Save Avatar ›"
            user={user}
            callback={() => setDialog(false)}
          />
        </Dialog>
        <div className="row">
          <div
            className="account-info"
            style={{ maxWidth: 700, margin: "0 auto" }}
          >
            <h1>Account</h1>

            <div className="row">
              <div className="col-sm-5 user-avatar">
                <h5>Avatar</h5>
                <div
                  className="avatar"
                  style={{
                    backgroundColor: avatarBackground(
                      user.hasura.avatarBackgroundColorIndex
                    ),
                  }}
                >
                  <img
                    style={{ maxWidth: "100%" }}
                    src={user.hasura.avatarUrl}
                    alt="User Avatar"
                  />
                </div>
                <p
                  onClick={() => {
                    setDialog(true);
                  }}
                  style={{ fontSize: "0.7em", marginTop: 10 }}
                >
                  Change
                </p>
              </div>

              <div className="col-sm-5">
                <h5>Account Level</h5>
                <img style={{ width: 100 }} src={proIcon} alt="At Full Pro" />

                <h5>Redeem Referral Code</h5>
                <TextField
                  className="dark-textfield"
                  value={referralCode}
                  onChange={(e) => setreferralCode(e.target.value)}
                />
                {referralMsg && <div className="highlight">{referralMsg}</div>}
                <div
                  style={{ display: "grid", marginTop: 20 }}
                  className="btn gradient"
                  onClick={() => redeemReferralCode()}
                >
                  Redeem ›
                </div>
              </div>
            </div>

            <h5>Email</h5>
            <TextField
              disabled
              fullWidth
              className="dark-textfield"
              value={user.firebase.email}
            />

            <h5>Username</h5>
            <TextField
              fullWidth
              className="dark-textfield"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />

            <h5>Website</h5>
            <TextField
              fullWidth
              className="dark-textfield"
              onChange={(e) => setWebsite(e.target.value)}
              default={user.hasura.website}
              value={website !== "" ? website : user.hasura.website}
            />

            <h5>Bio</h5>
            <TextField
              fullWidth
              rows={4}
              multiline
              className="dark-textfield"
              onChange={(e) => setBio(e.target.value)}
              default={user.hasura.bio}
              value={bio !== "" ? bio : user.hasura.bio}
            />

            <div className="row" style={{ marginTop: "3em" }}>
              <div className="col-sm-6">
                <div
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    saveUserInfo(
                      {
                        username:
                          username !== "" ? username : user.hasura.username,
                        website: website !== "" ? website : user.hasura.website,
                        bio: bio !== "" ? bio : user.hasura.bio,
                      },
                      setSaveAlert(true)
                    );
                  }}
                >
                  Save
                </div>
              </div>
              <div className="col-sm-6">
                <div style={{ width: "100%" }} className="btn gradient">
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex flex-wrap text-center justify-content-center"
        style={{ marginTop: 100 }}
      >
        <h3 className="w-100 pb-3">
          You need to be signed in to access this page.
        </h3>
        <Link to="/sign-in" className="btn gradient">
          Sign in
        </Link>
      </div>
    );
  }
};

export default Account;
