import React from "react";
import { Dialog } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

const PostModal = ({ children, close, open, hideCloseIcon }) => {
  return (
    <div>
      <Dialog
        style={{ width: "100%" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="post-modal"
        open={open}
        onClose={() => close(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <div>
            {!hideCloseIcon && (
              <div onClick={close} className="close">
                ✕
              </div>
            )}
            {children}
          </div>
        </Fade>
      </Dialog>
    </div>
  );
};

export default PostModal;
