import React from "react";
import "./index.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Signup from "./components/Auth/Signup";
import Signin from "./components/Auth/Signin";
import Header from "./components/Header";
import { PostsProvider } from "./context/Posts";
import { UserProvider } from "./context/User";
import Account from "./components/User/Account";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import Dashboard from "./components/Affliate/Dashboard";
import SearchPage from "./components/SearchPage";
import AffliateSignUp from "./components/Affliate/AffliateSignUp";
import Profile from "./components/User/Profile";
import WarrantyCenter from "./components/ProductRegistration/WarrantyCenter";
import firebase from './firebase.js'
// eslint-disable-next-line

import { ProductsProvider } from "./context/Products";


const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0047ba",
      },
      secondary: {
        main: "#ffffff"
      }
    },
  });
  return (
    <MuiThemeProvider theme={theme}>
      <UserProvider>
        <ProductsProvider>
          <PostsProvider>
            <Router>
              <>

                <Route
                  exact
                  path="/product-registration"
                  component={WarrantyCenter}
                />
                <Route exact path="/" component={WarrantyCenter} />
                <Route
                  exact
                  path="/affiliate-signup"
                  component={AffliateSignUp}
                />
                <Route exact path="/search" component={SearchPage} />
                <Route exact path="/sign-up" component={() => <Signup />} />
                <Route
                  exact
                  path="/sign-in"
                  component={() => <Signin title="Sign in" />}
                />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route path="/user/:id" component={Profile} />
                <Route path="/account" component={Account} />
              </>
            </Router>
          </PostsProvider>
        </ProductsProvider>
      </UserProvider>
    </MuiThemeProvider>
  );
};

export default App;
