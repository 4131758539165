import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useContext } from "react";
import { UserContext } from "../../context/User";

const FollowUserButton = ({ isFollowing, userId }) => {
  const [following, setFollowing] = React.useState(false);
  const { user } = useContext(UserContext);

  React.useEffect(() => {
    setFollowing(isFollowing);
  }, [isFollowing]);

  const followUser = () => {
    setFollowing(!following);

    axios.post(
      `https://atfullapi.blizzard.lighting/follow/${userId}`,
      {},
      { headers: { Authorization: `Bearer ${user.firebase.accessToken}` } }
    );
  };
  return (
    <Button
      onClick={() => followUser()}
      style={{ marginLeft: "1em" }}
      variant={following ? "contained" : "outlined"}
      color="primary"
    >
      {following ? "Following" : "Follow"}
    </Button>
  );
};

export default FollowUserButton;
