import { Dialog, TextField, Button } from "@material-ui/core";
import React, { useContext } from "react";
import { UserContext } from "../../context/User";
import DataBox from "./DataBox";
import DownloadBox from "./DownloadBox";
import PaymentPreferences from "./PaymentPreferences";
import VideoLogging from "./VideoLogging";
import { Link } from "react-router-dom";
import axios from "axios";

const Dashboard = () => {
  const { user, updateAffiliate, setUser } = useContext(UserContext);
  const [dialog, setDialog] = React.useState(false);
  const [dialogMode, setDialogMode] = React.useState("");

  const fetchCompanyInfo = () => {
    //eslint-disable-next-line
    const company = axios
      .post("https://api.blizzard.lighting/get-hs-company", {
        companyId: 796290530,
      })
      .then((res) => {
        const { company_type } = res.data.properties;
        if (
          company_type.value === "Affiliate" &&
          !user.hasura.affiliateProgram.active
        ) {
          axios
            .post(
              `https://atfullapi.blizzard.lighting/users/affiliate/${user.hasura.affiliateProgram.id}`,
              {
                active: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.firebase.accessToken}`,
                },
              }
            )
            .then(() => {
              const userObj = { ...user };
              userObj.hasura.affiliateProgram.active = true;
              console.log(userObj);
              setUser(userObj);
            })
            .catch((err) => console.log(err));
        } else if (
          company_type.value === "Pending Affiliate" &&
          user.hasura.affiliateProgram.active
        ) {
          console.log("here");
          axios
            .post(
              `https://atfullapi.blizzard.lighting/users/affiliate/${user.hasura.affiliateProgram.id}`,
              {
                active: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${user.firebase.accessToken}`,
                },
              }
            )
            .then(() => {
              const userObj = { ...user };
              userObj.hasura.affiliateProgram.active = false;
              console.log(userObj);
              setUser(userObj);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (user && user.hasura) {
      fetchCompanyInfo();
    }
    //eslint-disable-next-line
  }, [user]);

  const renderIncompletionText = (type) => {
    const affiliate = user.hasura.affiliateProgram;
    if (affiliate.contractSigned) {
      return "Please wait while we review your agreement";
    } else {
      return `Please sign your affiliate agreement to get your referral ${type}`;
    }
  };

  const renderDialogMode = () => {
    switch (dialogMode) {
      case "payment":
        return (
          <PaymentPreferences
            callback={() => {
              setDialog(false);
              setDialogMode("");
            }}
            user={user}
          />
        );
      case "signing":
        return (
          <iframe
            title="DocuSign"
            src={`https://powerforms.docusign.net/cd14e50f-4000-4055-8fd3-7fd6e8e599b9?env=na3&acct=eaa2eaa7-d2af-45f7-99d6-6ef1c95e13b1&Affiliate_Email=${user.firebase.email}&Affiliate_UserName=${user.hasura.username}`}
            width="600px"
            height="700px"
            onLoad={(e) => console.log(e)}
          />
        );
      case "video":
        return <VideoLogging user={user} updateAffiliate={updateAffiliate} />;
      default:
        return;
    }
  };
  if (user && user.hasura.affiliateProgram !== null) {
    const affiliate = user.hasura.affiliateProgram;
    return (
      <div id="aff-dashboard" className="mw-1240">
        <Dialog open={dialog} onClose={() => setDialog(false)}>
          <div className="dialog-container m-auto p-5">
            {renderDialogMode()}
          </div>
        </Dialog>
        <div className="row d-flex justify-content-between">
          <div className="col-lg-5 mb-5">
            <h4 className="pb-3">Affliate Quick Links</h4>
            <DownloadBox
              text="Enter payment preferences"
              link="www.google.com"
              iconInactive="sack-dollar"
              active={true}
              callback={() => {
                setDialog(true);
                setDialogMode("payment");
              }}
            />
            {/* <DownloadBox
              text="Sign your affiliate agreement"
              link="www.google.com"
              iconInactive="signature"
              active={affiliate.contractSigned}
              callback={() => {
                setDialog(true);
                setDialogMode("signing");
              }}
            /> */}
            <DownloadBox
              text="Log/view your videos"
              link="www.google.com"
              iconInactive="play-circle"
              active={affiliate.loggedVideos.length > 0}
              callback={() => {
                setDialog(true);
                setDialogMode("video");
              }}
            />
            <DownloadBox
              text="Download the At Full™ Media Kit"
              link="www.google.com"
              iconInactive="photo-video"
            />
          </div>
          <div className="col-lg-5 mb-6">
            <h4 className="pb-3">Referral Info</h4>
            <h5>Referral URL</h5>
            <TextField
              variant="filled"
              className="dark-textfield"
              value={
                user && user.hasura && affiliate.active
                  ? `https://www.atfull.blizzardpro.com/sign-up?ref=${user.hasura.affiliateProgram.promotionCode}`
                  : renderIncompletionText("url")
              }
              fullWidth
            />
            <p style={{ color: "darkgray", fontSize: ".8em" }}>
              Customers can use this url to automatically enter your referral
              info.
            </p>
            <h5>Referral Code</h5>
            <TextField
              variant="filled"
              className="dark-textfield"
              value={
                user && user.hasura && affiliate.active
                  ? user.hasura.affiliateProgram.promotionCode
                  : renderIncompletionText("code")
              }
              fullWidth
            />
            <p style={{ color: "darkgray", fontSize: ".8em" }}>
              Customers can enter this code manually within the app or at
              https://www.atfull.blizzardpro.com/account
            </p>
          </div>
        </div>
        <div className="metric">
          <div className="data-box">
            <div
              className={`bubble ${affiliate.active ? "green" : "red"} `}
            ></div>
            <h3>Program is</h3>
            <h2>{affiliate.active ? "Active" : "Pending"}</h2>
          </div>
          <DataBox
            metric="Referrals"
            value={affiliate.referrals}
            backgroundColor="#f47b68"
            icon="fas fa-user-plus"
            toolTipText="This the total number of referrals that have signed up."
          />
          <DataBox
            metric="Free Trials Started"
            value={affiliate.referralsStarted}
            backgroundColor="#f57731"
            icon="fas fa-flag-checkered"
            toolTipText="Referrals who have started their free trial."
          />
          <DataBox
            metric="Referrals Cancelled"
            value={affiliate.referralsCanceled}
            backgroundColor="#7289da"
            icon="fas fa-portal-exit"
            toolTipText="This the total number of referrals that have cancelled their free trial."
          />
          <DataBox
            metric="Referrals Expired"
            value={affiliate.referralsExpired}
            backgroundColor="#faa61a"
            icon="fas fa-dizzy"
            toolTipText="This the total number of referrals that let their trial elapse without subscribing"
          />
          <DataBox
            metric="Referrals Paid"
            value={affiliate.referralsPaid}
            backgroundColor="#9b84ee"
            icon="fas fa-money-bill-wave"
            toolTipText="This the total number of referrals that have converted."
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex flex-wrap text-center justify-content-center"
        style={{ marginTop: 100 }}
      >
        <h3 className="w-100 pb-3">
          You need to be an At Full™ to access this page.
        </h3>
        <Button
          component={Link}
          to="/sign-up?affiliate=1"
          variant="contained"
          color="primary"
        >
          Sign up to be an Affiliate
        </Button>
      </div>
    );
  }
};

export default Dashboard;
