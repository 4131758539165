import { Button, TextField } from "@material-ui/core";
import React from "react";
import { CSSTransition } from "react-transition-group";
import ResponsiveVideo from "../ResponsiveVideo";

const VideoLogging = ({ user, updateAffiliate }) => {
  const [youtubeUrl, setYoutubeUrl] = React.useState("");
  const [videoLogs, setVideoLogs] = React.useState([]);

  React.useEffect(() => {
    if (user && user.hasura.affiliateProgram) {
      setVideoLogs(user.hasura.affiliateProgram.loggedVideos);
    }
  }, [user]);

  const removeVideoFromLogs = (index) => {
    const logs = [...videoLogs];
    logs.splice(index, 1);

    console.log(logs);

    updateAffiliate(
      {
        loggedVideos: logs,
      },
      user.hasura.affiliateProgram.id
    );

    setVideoLogs(logs);
  };

  function youtube_parser(url) {
    if (url.includes(".com")) {
      console.log(url);
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    }
  }

  return (
    <div className="p-5">
      <h5>Log a video</h5>
      <p>
        Enter the url of your YouTube video and we'll promote it on our end.
      </p>
      <div className="d-flex">
        <TextField
          fullWidth
          style={{ width: "70%" }}
          value={youtubeUrl}
          onChange={(e) => setYoutubeUrl(e.target.value)}
          variant="filled"
          label="YouTube URL"
        />
        <Button
          onClick={() =>
            updateAffiliate(
              {
                loggedVideos: [
                  ...user.hasura.affiliateProgram.loggedVideos,
                  youtubeUrl,
                ],
              },
              user.hasura.affiliateProgram.id,
              () => setYoutubeUrl("")
            )
          }
          style={{ width: "30%" }}
          fullWidth
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </div>

      <CSSTransition
        in={videoLogs.length > 0}
        unmountOnExit
        timeout={200}
        classNames="dialog"
      >
        <div className="pt-5 row">
          <h5>Your Videos</h5>
          {videoLogs.map((videoUrl, index) => (
            <div
              className="col-sm-6 videolog-container"
              style={{ marginBottom: 30 }}
            >
              <ResponsiveVideo youtubeId={youtube_parser(videoUrl)} />
              <div
                className="d-flex align-items-center pt-2"
                onClick={() => removeVideoFromLogs(index)}
              >
                <i style={{ color: "red" }} className="fas fa-ban m-1"></i>
                Remove
              </div>
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default VideoLogging;
