import React from "react";

const DownloadBox = ({
  iconInactive,
  backgroundColor,
  link,
  text,
  callback,
  active,
}) => {
  return (
    <div
      className="dl-box"
      style={{ background: active ? "rgb(50 124 73)" : "#1b1b1b" }}
      onClick={() => {
        if (callback) {
          callback();
        }
      }}
    >
      <div className="icon-bubble" style={{ backgroundColor: backgroundColor }}>
        <i className={`fas fa-${iconInactive ? iconInactive : "check"}`}></i>
      </div>
      {text} ›
    </div>
  );
};

export default DownloadBox;
