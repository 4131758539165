import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { UserContext } from "./User";

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [products, setProducts] = useState([]);

  let headers;

  if (user && user.firebase) {
    headers = {
      headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
    };
  }

  const fetchProducts = () => {
    axios.get(`https://atfullapi.blizzard.lighting/products`).then((res) => {
      setProducts(res.data);
    });
  };

  React.useEffect(() => {
    if (user) {
      fetchProducts();
    }
  }, [user]);

  return (
    <ProductsContext.Provider
      value={{
        products,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
