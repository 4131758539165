import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "axios";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const saveUserInfo = (params, callback) => {
    axios
      .post("https://atfullapi.blizzard.lighting/users/update", params, {
        headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
      })
      .then((res) => {
        setUser({ firebase: user.firebase, hasura: res.data });
        if (callback) {
          callback();
        }
      });
  };
  const updateAffiliate = (params, id, callback) => {
    axios
      .post(
        `https://atfullapi.blizzard.lighting/users/affiliate/${id}`,
        params,
        {
          headers: { Authorization: `Bearer ${user.firebase.accessToken}` },
        }
      )
      .then((res) => {
        setUser({
          firebase: user.firebase,
          hasura: { ...user.hasura, affiliateProgram: res.data },
        });
        if (callback) {
          callback();
        }
      });
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        try {
          axios
            .get("https://atfullapi.blizzard.lighting/users/current", {
              headers: { Authorization: `Bearer ${user.accessToken}` },
            })
            .then((res) => {
              setUser({ firebase: { ...user }, hasura: { ...res.data } });
            });
        } catch (err) {
          console.log(err);
        }
      } else {
      }
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        saveUserInfo,
        updateAffiliate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
