import {
  TextField,
  InputAdornment,
  Button,
  Tooltip,
  Select,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import React, { useState } from "react";
import serial from "../../images/sample-serial.svg";
import moment from "moment";
const WarrantyProductAdd = ({
  purchaseType,
  userProducts,
  setUserProducts,
  products,
  dateOfPurchase,
}) => {
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedSerial, setSelectedSerial] = useState("");
  const productCondition = ["New", "Like New", "Refurbished"];
  const [quality, setQuality] = useState("New");

  const addable = selectedProduct && selectedSerial;

  const warrantyDuration = (type) => {
    switch (type) {
      case "Refurbished":
        return [3, "m"];
      default:
        return [3, "y"];
    }
  };

  const renderWarrantyInfo = (date, type) => {
    let warrantyType;
    const duration = warrantyDuration("Refurbished");

    const expiredDate = moment(date)
      .add(duration[0], duration[1])
      .format("M/D/YYYY");
    return (
      <span className="warranty-expired">
        3yr Extended: {moment(date).format("M/D/YYYY")} - {expiredDate}
      </span>
    );
  };

  const renderWarrantyProducts = () => {
    if (userProducts.length > 0) {
      return _.map(userProducts, (item) => {
        return (
          <div className="item">
            <i className="fas fa-check-circle"></i>
            <div className="item-meta">
              <div className="item-name">{item.name || item.productName}</div>
              <div className="item-serial">SN: {item.serialNumber}</div>
            </div>
            <i
              style={{ cursor: "pointer" }}
              onClick={() => removeProduct(item.serialNumber)}
              className="fas fa-trash"
            ></i>
          </div>
        );
      });
    }
  };

  const removeProduct = (serialNumber) => {
    const prods = _.filter(userProducts, (x) => {
      return x.serialNumber !== serialNumber;
    });
    setUserProducts(prods);
  };

  const addProduct = () => {
    setSelectedProduct();
    setSelectedSerial("");
    setQuality("New");
    setUserProducts([
      ...userProducts,
      {
        new: quality !== "Refurbished" ? true : false,
        condition: quality,
        dateOfPurchase: dateOfPurchase,
        productId: selectedProduct.id,
        serialNumber: selectedSerial,
        name: selectedProduct.name,
      },
    ]);
  };
  return (
    <div className="">
      {purchaseType === "thirdparty" && (
        <div className="warranty-adder">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Autocomplete
              key={selectedProduct}
              id="tags-outlined"
              options={products ? products : []}
              onChange={(e, v) => {
                setSelectedProduct(v);
              }}
              value={selectedProduct}
              renderOption={(option) => {
                const { name } = option;

                return <span style={{ color: "black" }}>{name}</span>;
              }}
              getOptionLabel={(prod) => prod.name}
              style={{ width: "65%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Product"
                  variant="filled"
                  fullWidth
                />
              )}
            />

            <Autocomplete
              key={productCondition}
              id="tags-outlined"
              options={productCondition}
              onChange={(e, v) => {
                setQuality(v);
              }}
              value={quality}
              renderOption={(option) => {
                return <span style={{ color: "black" }}>{option}</span>;
              }}
              getOptionLabel={(c) => c}
              style={{ width: "30%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Purchase Condition"
                  variant="filled"
                  fullWidth
                />
              )}
            />
          </div>
          <div>
            <TextField
              style={{ width: "100%", marginBottom: "1.2em" }}
              value={selectedSerial}
              onChange={(e) => setSelectedSerial(e.target.value)}
              placeholder="Serial Number"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fal fa-hashtag"></i>
                  </InputAdornment>
                ),
              }}
            />

            <Tooltip
              title={
                <>
                  <img width={250} src={serial} alt="Serial Example" />
                </>
              }
            >
              <p className="info-tooltip">Where is my serial #?</p>
            </Tooltip>
          </div>
          <Button
            type="submit"
            id="dark-bg-button"
            disabled={!addable}
            style={{ width: 150, marginTop: 20 }}
            variant="contained"
            onClick={() => addProduct()}
            color="primary"
            className="btn"
          >
            Add +
          </Button>
        </div>
      )}

      <div className="warranty-products">{renderWarrantyProducts()}</div>
    </div>
  );
};

export default WarrantyProductAdd;
