import _ from "lodash";
import React, { useContext } from "react";
import Comment from "./Comment";
import Commenter from "./Posts/Commenter";
import { UserContext } from "../context/User";
import { PostsContext } from "../context/Posts";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const CommentList = ({ comments, post }) => {
  const { user } = useContext(UserContext);
  const { parentId } = useContext(PostsContext);

  const renderComments = () => {
    if (comments.length > 0) {
      return _.map(comments, (comment) => {
        return <Comment parentId={parentId} post={post} comment={comment} />;
      });
    } else {
      return <p>No comments here</p>;
    }
  };

  const renderCommenter = () => {
    if (user) {
      return (
        <Commenter title="Share your thoughts" buttonText="Post" post={post} />
      );
    } else {
      return (
        <>
          <h5 style={{ marginTop: 50 }}>You need to be signed in to comment</h5>
          <Button
            component={Link}
            to="/sign-in"
            variant="outlined"
            color="primary"
          >
            Sign in ›
          </Button>
        </>
      );
    }
  };

  return (
    <div className="comments">
      <h3 style={{ marginBottom: "1em" }}>Comments</h3>
      {renderComments()}
      {renderCommenter()}
    </div>
  );
};

export default CommentList;
